import React, { lazy, Suspense } from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
const PostDetail = lazy(() => import("../components/PostDetail/PostDetail"))

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Teresa López - Blog personal"
        img="https://i.imgur.com/1tsVdbW.jpg?1"
      />
      <Suspense fallback={<>Loading</>}>
        <Bio />
        {posts.map(({ node }) => {
          return (
            <PostDetail
              title={node.frontmatter.title || node.fields.slug}
              slug={node.fields.slug}
              image={node.frontmatter.image}
              date={node.frontmatter.date}
              description={node.frontmatter.description || node.excerpt}
              key={node.fields.slug}
            />
          )
        })}
      </Suspense>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image
          }
        }
      }
    }
  }
`
